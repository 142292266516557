import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
		<path d="M2285 4053 c-44 -6 -187 -42 -210 -53 -11 -5 -54 -23 -95 -40 -204
-84 -407 -260 -490 -425 -14 -28 -28 -52 -31 -55 -7 -6 -25 -57 -45 -125 -8
-29 -17 -116 -20 -205 -5 -140 -3 -166 19 -270 26 -120 79 -251 136 -334 53
-77 181 -200 257 -246 49 -31 127 -70 139 -70 6 0 22 -6 36 -14 14 -7 48 -19
75 -25 145 -34 242 -42 599 -51 460 -12 526 -22 614 -96 l31 -26 3 334 c4 637
3 709 -17 746 -29 56 -88 114 -141 138 -48 23 -58 24 -318 24 -160 0 -286 -4
-311 -11 -29 -7 -57 -25 -85 -53 -34 -35 -43 -51 -47 -93 -8 -60 6 -107 44
-149 54 -61 72 -64 375 -46 l117 7 0 -112 0 -112 -83 -26 c-115 -35 -228 -48
-376 -43 -117 3 -131 6 -191 35 -88 43 -184 114 -221 166 -63 85 -82 143 -87
252 -4 88 -1 110 20 170 90 258 348 412 588 350 306 -79 409 -83 530 -19 41
21 93 59 118 84 44 45 112 141 112 158 0 5 -33 12 -72 15 -76 6 -155 32 -287
94 -170 80 -242 105 -351 122 -62 10 -272 13 -335 4z"/>
<path d="M1010 1745 c-79 -36 -112 -89 -112 -185 -1 -53 4 -77 23 -111 45 -83
151 -111 260 -69 54 20 69 19 69 -6 0 -11 8 -14 28 -12 l27 3 0 105 0 105
-100 0 -100 0 0 -35 c0 -35 0 -35 50 -40 l50 -5 -22 -22 c-18 -18 -35 -23 -72
-23 -67 0 -91 29 -91 110 0 81 24 110 90 110 46 0 80 -17 80 -41 0 -5 27 -9
61 -9 58 0 60 1 54 23 -11 37 -39 70 -79 90 -52 27 -168 33 -216 12z"/>
<path d="M1520 1560 l0 -200 163 2 162 3 0 45 0 45 -102 3 -102 3 -3 147 -3
147 -57 3 -58 3 0 -201z"/>
<path d="M2120 1740 c-78 -36 -113 -113 -100 -222 13 -104 98 -164 220 -156
61 5 119 25 146 52 65 66 65 226 0 292 -52 51 -190 69 -266 34z m170 -90 c16
-16 20 -33 20 -90 0 -87 -17 -110 -84 -110 -62 0 -86 31 -86 110 0 79 24 110
86 110 31 0 50 -6 64 -20z"/>
<path d="M2640 1559 l0 -201 153 4 c166 4 187 10 213 60 21 41 11 90 -25 121
l-28 23 28 32 c35 38 37 79 9 115 -29 37 -76 47 -222 47 l-128 0 0 -201z m240
101 c6 -12 8 -30 5 -40 -5 -17 -16 -20 -66 -20 l-59 0 0 33 c0 44 4 47 61 47
38 0 51 -4 59 -20z m15 -160 c11 -35 -12 -50 -76 -50 l-59 0 0 35 0 35 64 0
c56 0 65 -3 71 -20z"/>
<path d="M3319 1733 c-42 -106 -129 -350 -129 -361 0 -10 15 -12 58 -10 50 3
57 6 60 25 4 28 29 35 102 31 49 -3 56 -6 68 -30 12 -25 18 -28 67 -28 30 0
56 3 58 8 4 7 -32 108 -106 302 l-33 85 -67 3 c-65 3 -67 2 -78 -25z m96 -140
c29 -99 31 -93 -21 -93 -43 0 -46 1 -40 23 4 12 12 43 18 70 14 56 27 56 43 0z"/>
<path d="M3794 1747 c-2 -7 -3 -96 -2 -197 l3 -185 160 0 160 0 0 45 0 45 -97
3 -98 3 0 149 0 150 -60 0 c-40 0 -62 -4 -66 -13z"/>
<path d="M1980 1146 c0 -44 4 -76 10 -76 6 0 10 26 11 58 l1 57 26 -58 c37
-80 57 -75 57 16 0 81 -18 94 -25 17 l-5 -55 -21 55 c-12 31 -28 56 -38 58
-14 3 -16 -7 -16 -72z"/>
<path d="M2588 1203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2405 1180 c-11 -12 -13 -20 -6 -25 6 -3 11 -19 11 -34 0 -15 5 -32
12 -39 17 -17 48 -15 48 3 0 8 -7 15 -15 15 -9 0 -15 9 -15 25 0 16 6 25 15
25 22 0 18 16 -9 33 -22 15 -25 15 -41 -3z"/>
<path d="M2224 1164 c-14 -11 -15 -14 -3 -11 9 3 22 1 30 -3 14 -9 14 -9 -36
-25 -43 -13 7 -62 52 -51 21 6 24 12 21 43 -4 47 -35 70 -64 47z m39 -59 c-6
-18 -34 -16 -41 3 -2 7 6 12 22 12 19 0 24 -4 19 -15z"/>
<path d="M2584 1167 c-9 -25 0 -90 14 -95 8 -2 12 11 12 47 0 49 -15 76 -26
48z"/>
<path d="M2946 1167 c-13 -9 -17 -23 -14 -52 4 -54 28 -59 28 -5 0 34 3 41 18
38 12 -2 18 -14 20 -41 4 -52 22 -46 22 8 0 40 -3 47 -26 55 -31 12 -27 13
-48 -3z"/>
<path d="M2732 1158 c-20 -20 -14 -56 13 -74 23 -15 27 -15 50 0 27 18 33 54
13 74 -15 15 -61 15 -76 0z m63 -33 c0 -22 -31 -33 -47 -17 -17 17 -1 44 24
40 15 -2 23 -10 23 -23z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
